import * as React from "react";
import { TitleUuidPair } from "../gwangju-types";
import NonPreloadLink from "../components/non-preload-link";

interface SiblingProps {
  siblings: TitleUuidPair[];
}

export default class Sibling extends React.Component<SiblingProps> {
  public render() {
    const mapSiblingElements = (data: TitleUuidPair[]) => {
      return data.map((headlinePair, i) => {
        if (headlinePair.isSeparator != undefined && headlinePair.isSeparator) {
          return (
            <li key={i} className="text-green-800">
              {headlinePair.title}
            </li>
          );
        } else if (
          headlinePair.isReserved != undefined &&
          headlinePair.isReserved
        ) {
          return (
            <li key={i} className="text-gray-500">
              {headlinePair.title}
            </li>
          );
        } else {
          return (
            <li key={i} className="hover:text-blue-500">
              <NonPreloadLink url={"/posts/" + headlinePair.uuid}>
                {headlinePair.title}
              </NonPreloadLink>
            </li>
          );
        }
      });
    };

    const siblingCount = this.props.siblings.length;
    if (siblingCount > 20) {
      return (
        <nav>
          <div className="flex flex-row flex-wrap w-full p-1">
            <div className="w-full lg:w-1/3 font-mono pl-5">
              <ul>
                {mapSiblingElements(
                  this.props.siblings.slice(0, siblingCount / 3)
                )}
              </ul>
            </div>
            <div className="w-full lg:w-1/3 font-mono pl-5">
              <ul>
                {mapSiblingElements(
                  this.props.siblings.slice(
                    siblingCount / 3,
                    (siblingCount / 3) * 2
                  )
                )}
              </ul>
            </div>
            <div className="w-full lg:w-1/3 font-mono pl-5">
              <ul>
                {mapSiblingElements(
                  this.props.siblings.slice(
                    (siblingCount / 3) * 2,
                    siblingCount
                  )
                )}
              </ul>
            </div>
          </div>
        </nav>
      );
    } else if (siblingCount > 10) {
      return (
        <nav>
          <div className="flex flex-row flex-wrap w-full p-1">
            <div className="w-full lg:w-1/2 font-mono pl-5">
              <ul>
                {mapSiblingElements(
                  this.props.siblings.slice(0, siblingCount / 2)
                )}
              </ul>
            </div>
            <div className="w-full lg:w-1/2 font-mono pl-5">
              <ul>
                {mapSiblingElements(
                  this.props.siblings.slice(siblingCount / 2, siblingCount)
                )}
              </ul>
            </div>
          </div>
        </nav>
      );
    } else {
      return (
        <nav>
          <div className="flex flex-row flex-wrap w-full p-1">
            <div className="w-full font-mono pl-5">
              <ul>{mapSiblingElements(this.props.siblings)}</ul>
            </div>
          </div>
        </nav>
      );
    }
  }
}
