import * as React from "react";
import { ContentsParams, IndexParams, TitleUuidPair } from "../gwangju-types";
import Collapsible from "react-collapsible";
import Sibling from "../components/sibling";
import { Helmet } from "react-helmet";

interface IndexContentProps {
  indexParameters: IndexParams;
}

export default class IndexContent extends React.Component<IndexContentProps> {
  public render() {
    const mapDescription = () => {
      return (
        <Helmet>
          <meta
            name="description"
            content="Software Engineering and Trip Blog"
          />
        </Helmet>
      );
    };

    const mapHeadlineElements = (
      headlines: TitleUuidPair[],
      siblings: { [name: string]: TitleUuidPair[] }
    ) => {
      return headlines.map((headlinePair, i) => {
        return (
          <div key={i} className="p-2">
            <Collapsible
              trigger={headlinePair.title}
              triggerClassName="text-2xl hover:text-blue-500"
              triggerOpenedClassName="text-2xl hover:text-blue-500"
            >
              <Sibling siblings={siblings[headlinePair.sibling]} />
            </Collapsible>
          </div>
        );
      });
    };

    return (
      <div>
        {mapDescription()}
        <div className="bottom-auto w-full p-1 text-4xl font-medium">
          Headlines
        </div>
        <div>
          <div className="bottom-auto w-full p-1">
            {mapHeadlineElements(
              this.props.indexParameters.headlines,
              this.props.indexParameters.siblings
            )}
          </div>
        </div>
      </div>
    );
  }
}
