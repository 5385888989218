import { PageRendererProps } from "gatsby";
import * as React from "react";
import Layout from "../layouts/layout";
import IndexContent from "../components/index-content";

interface IndexTemplateProps extends PageRendererProps {
  pageContext: any;
  data: any;
}

export default class IndexTemplate extends React.Component<IndexTemplateProps> {
  public render() {
    return (
      <Layout layoutParams={this.props.pageContext.layoutParams}>
        <IndexContent
          indexParameters={this.props.pageContext.indexParams}
        ></IndexContent>
      </Layout>
    );
  }
}
